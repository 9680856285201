import { ordenesSalidaService } from "@/_services";
export const ordenSalida = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    entity: {
      data: [],
      status: {},
    },
    cancel: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },

    post: {
      data: [],
      status: {},
    },

    copiar: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getOrdenesSalidaRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getOrdenesSalidaSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getOrdenesSalidaError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    cancelOrdenSalidaRequest(state) {
      state.cancel.status = {
        canceling: true,
      };
    },
    cancelOrdenSalidaError(state, error) {
      state.cancel.data = null;
      state.cancel.status = {
        error,
      };
    },
    cancelOrdenSalidaSuccess(state, response) {
      state.cancel.data = response;
      state.cancel.status = {
        canceled: true,
      };
    },
    postOrdenSalidaRequest(state) {
      state.post.status = {
        creating: true,
      };
    },
    postOrdenSalidaError(state, error) {
      state.post.data = null;
      state.post.status = {
        error,
      };
    },
    postOrdenSalidaSuccess(state, response) {
      state.post.data = response;
      state.post.status = {
        created: true,
      };
    },
    updateOrdenSalidaRequest(state) {
      state.update.status = {
        updating: true,
      };
    },
    updateOrdenSalidaError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    updateOrdenSalidaSuccess(state, response) {
      state.update.data = response;
      state.update.status = {
        updated: true,
      };
    },
    getOrdenSalidaByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getOrdenSalidaByIdError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    getOrdenSalidaByIdSuccess(state, response) {
      state.entity.data = response;
      state.entity.status = {
        loaded: true,
      };
    },
    copiarOrdenSalidaRequest(state) {
      state.copiar.status = {
        loading: true,
      };
    },
    copiarOrdenSalidaError(state, error) {
      state.copiar.data = null;
      state.copiar.status = {
        error,
      };
    },
    copiarOrdenSalidaSuccess(state, response) {
      state.copiar.data = response;
      state.copiar.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getOrdenesSalida({ commit }, request) {
      commit("getOrdenesSalidaRequest");
      ordenesSalidaService
        .getOrdenesSalida(request.pagination, request.query)
        .then((response) => {
          commit("getOrdenesSalidaSuccess", response);
        })
        .catch((error) => {
          commit("getOrdenesSalidaError", error);
        });
    },
    cancelOrdenSalidaById({ commit }, id) {
      commit("cancelOrdenSalidaRequest");
      ordenesSalidaService
        .cancelOrdenSalidaById(id)
        .then((res) => {
          commit("cancelOrdenSalidaSuccess", res);
        })
        .catch((error) => {
          commit("cancelOrdenSalidaError", error);
        });
    },
    postOrdenSalida({ commit }, request) {
      commit("postOrdenSalidaRequest");
      ordenesSalidaService
        .postOrdenSalida(request)
        .then((res) => {
          commit("postOrdenSalidaSuccess", res);
        })
        .catch((error) => {
          commit("postOrdenSalidaError", error);
        });
    },
    updateOrdenSalida({ commit }, request) {
      commit("updateOrdenSalidaRequest");
      ordenesSalidaService
        .updateOrdenSalida(request.id, request.body)
        .then((res) => {
          commit("updateOrdenSalidaSuccess", res);
        })
        .catch((error) => {
          commit("updateOrdenSalidaError", error);
        });
    },
    getOrdenSalidaById({ commit }, id) {
      commit("getOrdenSalidaByIdRequest");
      ordenesSalidaService
        .getOrdenSalidaById(id)
        .then((res) => {
          commit("getOrdenSalidaByIdSuccess", res);
        })
        .catch((error) => {
          commit("getOrdenSalidaByIdError", error);
        });
    },
    copiarOrdenSalidaById({ commit }, id) {
      commit("copiarOrdenSalidaRequest");
      ordenesSalidaService
        .copiarOrdenSalidaById(id)
        .then((res) => {
          commit("copiarOrdenSalidaSuccess", res);
        })
        .catch((error) => {
          commit("copiarOrdenSalidaError", error);
        });
    },
  },
};
