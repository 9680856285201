import { render, staticRenderFns } from "./NotificationItem.vue?vue&type=template&id=5f09fff0&scoped=true&"
import script from "./NotificationItem.vue?vue&type=script&lang=js&"
export * from "./NotificationItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f09fff0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassiantemp/11dc8472-7ae2-5dbf-bec8-d9a1dcad8517/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f09fff0')) {
      api.createRecord('5f09fff0', component.options)
    } else {
      api.reload('5f09fff0', component.options)
    }
    module.hot.accept("./NotificationItem.vue?vue&type=template&id=5f09fff0&scoped=true&", function () {
      api.rerender('5f09fff0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Dashboard/Dashboard/Stats/NotificationItem.vue"
export default component.exports