import { medidasService } from "@/_services";
export const medida = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getMedidasRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getMedidasSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getMedidasError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
  },
  actions: {
    getMedidas({ commit }, req) {
      commit("getMedidasRequest");
      medidasService
        .getMedidas(req.query)
        .then((response) => {
          commit("getMedidasSuccess", response);
        })
        .catch((error) => {
          commit("getMedidasError", error);
        });
    },
  },
};
