import { createAxiosLogistica } from "./axiosHttpMarconi";

export const ordenesEntradaService = {
  getOrdenesEntrada,
  cancelOrdenEntradaById,
  postOrdenEntrada,
  updateOrdenEntrada,
  getOrdenEntradaById,
};

function getOrdenesEntrada(pagination, filters) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-entrada`;
  const params = new URLSearchParams({ ...pagination, ...filters });

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath);
}

function cancelOrdenEntradaById(ordenEntradaId) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-entrada/${ordenEntradaId}`;
  return axios.delete(urlPath);
}

function postOrdenEntrada(request) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-entrada`;
  return axios.post(urlPath, request);
}

function updateOrdenEntrada(id, body) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-entrada/${id}`;
  return axios.put(urlPath, body);
}

function getOrdenEntradaById(id) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-entrada/${id}`;
  return axios.get(urlPath);
}
