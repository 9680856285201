var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", {
    staticClass: "modal-status",
    on: { hide: _vm.hideModal },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("h5", { staticStyle: { color: "#212529" } }, [
              _vm._v("Cambiar Contraseña"),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "el-form",
              {
                ref: "changePassword",
                attrs: { model: _vm.usuario, rules: _vm.rulesPassword },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "password", label: "Contraseña actual" } },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "Contraseña actual",
                        "show-password": "",
                      },
                      model: {
                        value: _vm.usuario.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.usuario, "password", $$v)
                        },
                        expression: "usuario.password",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "newPassword", label: "Nueva contraseña" } },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "Nueva contraseña",
                        "show-password": "",
                      },
                      model: {
                        value: _vm.usuario.newPassword,
                        callback: function ($$v) {
                          _vm.$set(_vm.usuario, "newPassword", $$v)
                        },
                        expression: "usuario.newPassword",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "confirmPassword",
                      label: "Confirmar contraseña",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "Confirmar Contraseña",
                        "show-password": "",
                      },
                      model: {
                        value: _vm.usuario.confirmPassword,
                        callback: function ($$v) {
                          _vm.$set(_vm.usuario, "confirmPassword", $$v)
                        },
                        expression: "usuario.confirmPassword",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button" },
                on: { click: _vm.hideModal },
              },
              [_vm._v(" Cancelar ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.editedPassword },
              },
              [_vm._v(" Aceptar ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }