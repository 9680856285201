import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";
// Dashboard pages
import Overview from "src/pages/Dashboard/Dashboard/Overview.vue";
import Stats from "src/pages/Dashboard/Dashboard/Stats.vue";

// Usuarios page
import ListaUsuarios from "src/pages/Views/ListaUsuarios.vue";
import NuevoUsuario from "src/pages/Views/NuevoUsuario.vue";

// Pages
import User from "src/pages/Dashboard/Pages/UserProfile.vue";
import TimeLine from "src/pages/Dashboard/Pages/TimeLinePage.vue";
import Login from "src/pages/Dashboard/Pages/Login.vue";
import Register from "src/pages/Dashboard/Pages/Register.vue";
import Lock from "src/pages/Dashboard/Pages/Lock.vue";
import RecoverPassword from "src/pages/Dashboard/Pages/RecoverPassword.vue";
import ResetPassword from "src/pages/Dashboard/Pages/ResetPassword.vue";

// Components pages
import Buttons from "src/pages/Dashboard/Components/Buttons.vue";
import GridSystem from "src/pages/Dashboard/Components/GridSystem.vue";
import Panels from "src/pages/Dashboard/Components/Panels.vue";
const SweetAlert = () =>
  import("src/pages/Dashboard/Components/SweetAlert.vue");
import Notifications from "src/pages/Dashboard/Components/Notifications.vue";
import Icons from "src/pages/Dashboard/Components/Icons.vue";
import Typography from "src/pages/Dashboard/Components/Typography.vue";

// Forms pages
const RegularForms = () => import("src/pages/Dashboard/Forms/RegularForms.vue");
const ExtendedForms = () =>
  import("src/pages/Dashboard/Forms/ExtendedForms.vue");
const ValidationForms = () =>
  import("src/pages/Dashboard/Forms/ValidationForms.vue");
const Wizard = () => import("src/pages/Dashboard/Forms/Wizard.vue");

// TableList pages
const RegularTables = () =>
  import("src/pages/Dashboard/Tables/RegularTables.vue");
const ExtendedTables = () =>
  import("src/pages/Dashboard/Tables/ExtendedTables.vue");
const PaginatedTables = () =>
  import("src/pages/Dashboard/Tables/PaginatedTables.vue");
// Maps pages
const GoogleMaps = () => import("src/pages/Dashboard/Maps/GoogleMaps.vue");
const FullScreenMap = () =>
  import("src/pages/Dashboard/Maps/FullScreenMap.vue");
const VectorMaps = () => import("src/pages/Dashboard/Maps/VectorMapsPage.vue");

// Calendar
const Calendar = () => import("src/pages/Dashboard/Calendar/CalendarRoute.vue");
// Charts
const Charts = () => import("src/pages/Dashboard/Charts.vue");
// Franquicia
const Franquicia = () => import("src/pages/Views/Franquicia/Franquicia.vue");
const VerFranquicia = () =>
  import("src/pages/Views/Franquicia/VerFranquicia.vue");
const AltaEnvios = () => import("src/pages/Views/AltaEnvios/AltaEnvios.vue");
// Envios
const Envios = () => import("src/pages/Views/Envios/Envios.vue");
const ListaImportaciones = () =>
  import("src/pages/Views/Importacion/ListaImportaciones.vue");
const Seguimiento = () => import("src/pages/Views/Seguimiento.vue");

//Facturacion
const ListaFacturas = () =>
  import("src/pages/Views/Facturacion/ListaFacturas.vue");

//Facturacion
const LibretaContactos = () =>
  import("src/pages/Views/LibretaContacto/DireccionesHabituales.vue");

//Incidencias
const IncidenciaDetalle = () =>
  import("src/pages/Views/DetalleIncidencia/DetalleIncidencia.vue");
const Incidencias = () => import("src/pages/Views/Incidencias/Incidencias.vue");

//GestionDatos
const Usuarios = () => import("src/pages/Views/GestionDatos/Usuarios.vue");
const Forbidden = () => import("src/pages/Views/NoAuthorizedPage.vue");

//RMA
const RMAWizard = () => import("src/pages/Views/RMAWizard/RMAWizard.vue");
const rma = () => import("src/pages/Views/RMA/Rma.vue");
const RMAGenerico = () => import("src/pages/Views/RMAGenerico/RMAGenerico.vue");
const ImportacionesRma = () =>
  import("src/pages/Views/RMA/ImportacionesRma.vue");

//HELP
const Help = () => import("src/pages/Views/Help/Help.vue");
const EnviosAgrupados = () =>
  import("src/pages/Views/EnviosAgrupados/EnviosAgrupados");

// LOGISTICA
const Articulos = () => import("src/pages/Views/Articulos/Articulos.vue");
const Packs = () => import("src/pages/Views/Packs/Packs.vue");
const OrdenesEntrada = () =>
  import("src/pages/Views/OrdenesEntrada/OrdenesEntrada.vue");
const OrdenesSalida = () =>
  import("src/pages/Views/OrdenesSalida/OrdenesSalida.vue");
const OrdenEntrada = () =>
  import("src/pages/Views/OrdenEntrada/OrdenEntrada.vue");
const OrdenSalida = () => import("src/pages/Views/OrdenSalida/OrdenSalida.vue");

let recoverPage = {
  path: "/recover_password",
  name: "Recuperar Contraseña",
  component: RecoverPassword,
};
let resetPage = {
  path: "/restore_login",
  name: "Restaurar Contraseña",
  component: ResetPassword,
};
let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      component: Buttons,
    },
    {
      path: "grid-system",
      name: "Grid System",
      component: GridSystem,
    },
    {
      path: "panels",
      name: "Panels",
      component: Panels,
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      component: SweetAlert,
    },
    {
      path: "notifications",
      name: "Notifications",
      component: Notifications,
    },
    {
      path: "icons",
      name: "Icons",
      component: Icons,
    },
    {
      path: "typography",
      name: "Typography",
      component: Typography,
    },
  ],
};
let usuarios = {
  path: "/usuarios",
  component: DashboardLayout,
  redirect: "/usuarios/listado",
  children: [
    {
      path: "listado",
      name: "Listado Usuarios",
      component: ListaUsuarios,
      props: true,
    },
    {
      path: "nuevousuario",
      name: "NuevoUsuario",
      component: NuevoUsuario,
      props: true,
    },
  ],
};
let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      component: RegularForms,
    },
    {
      path: "extended",
      name: "Extended Forms",
      component: ExtendedForms,
    },
    {
      path: "validation",
      name: "Validation Forms",
      component: ValidationForms,
    },
    {
      path: "wizard",
      name: "Wizard",
      component: Wizard,
    },
  ],
};

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      component: RegularTables,
    },
    {
      path: "extended",
      name: "Extended Tables",
      component: ExtendedTables,
    },
    {
      path: "paginated",
      name: "Paginated Tables",
      component: PaginatedTables,
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      component: GoogleMaps,
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      component: FullScreenMap,
    },
    {
      path: "vector-map",
      name: "Vector Map",
      component: VectorMaps,
    },
  ],
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      component: User,
    },
    {
      path: "timeline",
      name: "Timeline Page",
      component: TimeLine,
    },
  ],
};

let loginPage = {
  path: "/login",
  name: "Login",
  component: Login,
};

let rmaWizard = {
  path: "/rma",
  name: "RMA",
  component: RMAWizard,
};

let forbidden = {
  path: "/forbidden",
  name: "Forbidden",
  component: Forbidden,
};

let rmaGenerico = {
  path: "/rma/generico",
  name: "RMA Generico",
  component: RMAGenerico,
};

let registerPage = {
  path: "/register",
  name: "Register",
  component: Register,
};

let lockPage = {
  path: "/login",
  name: "Lock",
  component: Lock,
};

let seguimiento = {
  path: "/seguimiento",
  name: "Seguimiento Envio",
  component: Seguimiento,
};

const routes = [
  {
    path: "/",
    redirect: "/cliente/overview",
  },
  recoverPage,
  resetPage,
  usuarios,
  loginPage,
  componentsMenu,
  seguimiento,
  rmaWizard,
  {
    path: "/no-auth",
    name: "Forbidden",
    component: Forbidden,
  },
  rmaGenerico,
  {
    path: "/cliente",
    component: DashboardLayout,
    redirect: "/cliente/overview",
    children: [
      {
        path: "/logistica/articulos",
        name: "Artículos",
        component: Articulos,
      },
      {
        path: "/logistica/packs",
        name: "Packs",
        component: Packs,
      },
      {
        path: "/logistica/ordenes-entrada",
        name: "Ordenes entrada",
        component: OrdenesEntrada,
      },
      {
        path: "/logistica/ordenes-salida",
        name: "Ordenes salida",
        component: OrdenesSalida,
      },
      {
        path: "/logistica/ordenes-entrada/nueva",
        name: "Orden entrada",
        component: OrdenEntrada,
      },
      {
        path: "/logistica/ordenes-entrada/:id",
        name: "Orden entrada",
        component: OrdenEntrada,
      },
      {
        path: "/logistica/ordenes-salida/nueva",
        name: "Orden salida",
        component: OrdenSalida,
      },
      {
        path: "/logistica/ordenes-salida/:id",
        name: "Orden salida",
        component: OrdenSalida,
      },
      {
        path: "overview",
        name: "Dashboard",
        component: Overview,
      },
      {
        path: "envios",
        name: "Envíos",
        component: Envios,
      },
      {
        path: "envios-agrupados",
        name: "Envíos agrupados",
        component: EnviosAgrupados,
      },
      {
        path: "importacion",
        name: "Importación",
        component: ListaImportaciones,
      },
      {
        path: "nuevoenvio",
        name: "Nuevo Envío",
        component: AltaEnvios,
        props: true,
      },
      {
        path: "nuevoenvio/:id",
        name: "Nuevo Envío",
        component: AltaEnvios,
        props: true,
      },
      {
        path: "facturas",
        name: "Facturas",
        component: ListaFacturas,
      },
      {
        path: "libretaContacto",
        name: "Libreta de Contactos",
        component: LibretaContactos,
      },
      {
        path: "incidencias/detalle/:id",
        name: "Detalle incidencia",
        component: IncidenciaDetalle,
      },
      {
        path: "incidencias",
        name: "Incidencias",
        component: Incidencias,
      },
      {
        path: "usuarios",
        name: "Usuarios",
        component: Usuarios,
      },
      {
        path: "rma",
        name: "RMA",
        component: rma,
      },
      {
        path: "forbidden",
        name: "Forbidden",
        component: Forbidden,
      },
      {
        path: "importacionesRma",
        name: "Importaciones Rma",
        component: ImportacionesRma,
      },
      {
        path: "help",
        name: "Ayuda",
        component: Help,
      },

      forbidden,
    ],
  },
  {
    path: "*",
    component: NotFound,
  },
];

export default routes;
