import { createAxiosLogistica } from "./axiosHttpMarconi";

export const articulosService = {
  getArticulos,
  deleteArticulo,
  postArticulo,
  updateArticulo,
  getArticuloById,
};

function getArticulos(pagination, filters) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/articulo`;
  const params = new URLSearchParams({ ...pagination, ...filters });

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath);
}

function deleteArticulo(articuloId) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/articulo/${articuloId}`;
  return axios.delete(urlPath);
}

function postArticulo(request) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/articulo`;
  return axios.post(urlPath, request);
}

function updateArticulo(id, body) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/articulo/${id}`;
  return axios.put(urlPath, body);
}

function getArticuloById(id) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/articulo/${id}`;
  return axios.get(urlPath);
}
