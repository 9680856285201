var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-pagination", {
    attrs: {
      "current-page": _vm.pagination.currentPage,
      "pager-count": 5,
      "v-model": _vm.pagination.currentPage,
      "page-sizes": _vm.pagination.perPageOptions,
      "page-size": _vm.pagination.perPage,
      layout: "total, sizes, prev, pager, next, jumper",
      total: _vm.pagination.totalItems,
    },
    on: {
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handlePageChange,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }