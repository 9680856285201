import { packsService } from "@/_services";
export const pack = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    entity: {
      data: [],
      status: {},
    },
    delete: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },
    post: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getPacksRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getPacksSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getPacksError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    deletePackRequest(state) {
      state.delete.status = {
        deleting: true,
      };
    },
    deletePackError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    deletePackSuccess(state, response) {
      state.delete.data = response;
      state.delete.status = {
        deleted: true,
      };
    },
    postPackRequest(state) {
      state.post.status = {
        creating: true,
      };
    },
    postPackError(state, error) {
      state.post.data = null;
      state.post.status = {
        error,
      };
    },
    postPackSuccess(state, response) {
      state.post.data = response;
      state.post.status = {
        created: true,
      };
    },
    updatePackRequest(state) {
      state.update.status = {
        updating: true,
      };
    },
    updatePackError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    updatePackSuccess(state, response) {
      state.update.data = response;
      state.update.status = {
        updated: true,
      };
    },
    getPackByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getPackByIdError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    getPackByIdSuccess(state, response) {
      state.entity.data = response;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getPacks({ commit }, request) {
      commit("getPacksRequest");
      packsService
        .getPacks(request.pagination, request.query)
        .then((response) => {
          commit("getPacksSuccess", response);
        })
        .catch((error) => {
          commit("getPacksError", error);
        });
    },
    deletePack({ commit }, id) {
      commit("deletePackRequest");
      packsService
        .deletePack(id)
        .then((res) => {
          commit("deletePackSuccess", res);
        })
        .catch((error) => {
          commit("deletePackError", error);
        });
    },
    postPack({ commit }, request) {
      commit("postPackRequest");
      packsService
        .postPack(request)
        .then((res) => {
          commit("postPackSuccess", res);
        })
        .catch((error) => {
          commit("postPackError", error);
        });
    },
    updatePack({ commit }, request) {
      commit("updatePackRequest");
      packsService
        .updatePack(request.id, request.body)
        .then((res) => {
          commit("updatePackSuccess", res);
        })
        .catch((error) => {
          commit("updatePackError", error);
        });
    },
    getPackById({ commit }, id) {
      commit("getPackByIdRequest");
      packsService
        .getPackById(id)
        .then((res) => {
          commit("getPackByIdSuccess", res);
        })
        .catch((error) => {
          commit("getPackByIdError", error);
        });
    },
  },
};
