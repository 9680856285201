import {
  createAxiosLogistica,
  createAxiosHeadersLogistica,
} from "./axiosHttpMarconi";

export const ordenesSalidaService = {
  getOrdenesSalida,
  cancelOrdenSalidaById,
  postOrdenSalida,
  updateOrdenSalida,
  getOrdenSalidaById,
  copiarOrdenSalidaById,
};

function getOrdenesSalida(pagination, filters) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-salida`;
  const params = new URLSearchParams({ ...pagination, ...filters });

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath);
}

function cancelOrdenSalidaById(ordenSalidaId) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-salida/${ordenSalidaId}`;
  return axios.delete(urlPath);
}

function postOrdenSalida(request) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-salida`;
  return axios.post(urlPath, request);
}

function updateOrdenSalida(id, body) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-salida/${id}`;
  return axios.put(urlPath, body);
}

function getOrdenSalidaById(id) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-salida/${id}`;
  return axios.get(urlPath);
}

function copiarOrdenSalidaById(ordenSalidaId) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/orden-salida/${ordenSalidaId}/copiar`;
  return axios.post(urlPath);
}
