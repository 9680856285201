import { articulosService } from "@/_services";
export const articulo = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    entity: {
      data: [],
      status: {},
    },
    delete: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },
    post: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getArticulosRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getArticulosSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getArticulosError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    deleteArticuloRequest(state) {
      state.delete.status = {
        deleting: true,
      };
    },
    deleteArticuloError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    deleteArticuloSuccess(state, response) {
      state.delete.data = response;
      state.delete.status = {
        deleted: true,
      };
    },
    postArticuloRequest(state) {
      state.post.status = {
        creating: true,
      };
    },
    postArticuloError(state, error) {
      state.post.data = null;
      state.post.status = {
        error,
      };
    },
    postArticuloSuccess(state, response) {
      state.post.data = response;
      state.post.status = {
        created: true,
      };
    },
    updateArticuloRequest(state) {
      state.update.status = {
        updating: true,
      };
    },
    updateArticuloError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    updateArticuloSuccess(state, response) {
      state.update.data = response;
      state.update.status = {
        updated: true,
      };
    },
    getArticuloByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getArticuloByIdError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    getArticuloByIdSuccess(state, response) {
      state.entity.data = response;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getArticulos({ commit }, request) {
      commit("getArticulosRequest");
      articulosService
        .getArticulos(request.pagination, request.query)
        .then((response) => {
          commit("getArticulosSuccess", response);
        })
        .catch((error) => {
          commit("getArticulosError", error);
        });
    },
    deleteArticulo({ commit }, id) {
      commit("deleteArticuloRequest");
      articulosService
        .deleteArticulo(id)
        .then((res) => {
          commit("deleteArticuloSuccess", res);
        })
        .catch((error) => {
          commit("deleteArticuloError", error);
        });
    },
    postArticulo({ commit }, request) {
      commit("postArticuloRequest");
      articulosService
        .postArticulo(request)
        .then((res) => {
          commit("postArticuloSuccess", res);
        })
        .catch((error) => {
          commit("postArticuloError", error);
        });
    },
    updateArticulo({ commit }, request) {
      commit("updateArticuloRequest");
      articulosService
        .updateArticulo(request.id, request.body)
        .then((res) => {
          commit("updateArticuloSuccess", res);
        })
        .catch((error) => {
          commit("updateArticuloError", error);
        });
    },
    getArticuloById({ commit }, id) {
      commit("getArticuloByIdRequest");
      articulosService
        .getArticuloById(id)
        .then((res) => {
          commit("getArticuloByIdSuccess", res);
        })
        .catch((error) => {
          commit("getArticuloByIdError", error);
        });
    },
  },
};
