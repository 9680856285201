var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-9 col-sm-7" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xl-6 col-md-6 col-sm-12" },
          [
            _c(
              "stats-card",
              {
                attrs: {
                  title: _vm.dashboard.totals.national.toString(),
                  subTitle: "Nacionales",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "icon-secondary",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("i", { staticClass: "fa fa-home text-secondary" })]
                ),
                _c("template", { slot: "footer" }, [
                  _c("i", { staticClass: "fa fa-calendar" }),
                  _vm._v(
                    "Mes anterior: " +
                      _vm._s(_vm.dashboard.totals.national_last) +
                      " "
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xl-6 col-md-6 col-sm-12" },
          [
            _c(
              "stats-card",
              {
                attrs: {
                  title: _vm.dashboard.totals.international.toString(),
                  subTitle: "Internacionales",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "icon-secondary",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_c("i", { staticClass: "fa fa-globe text-secondary" })]
                ),
                _c("template", { slot: "footer" }, [
                  _c("i", { staticClass: "fa fa-calendar" }),
                  _vm._v(
                    "Mes anterior: " +
                      _vm._s(_vm.dashboard.totals.international_last) +
                      " "
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6 col-sm-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(" Top 10 de ubicaciones de entrega este mes "),
                ]),
                _vm.loading
                  ? _c("el-skeleton", {
                      staticStyle: {
                        width: "100%",
                        padding: "10px",
                        height: "314px",
                      },
                      attrs: { rows: 6 },
                    })
                  : _c("CitiesPieChart", {
                      staticClass: "p-2",
                      attrs: { data: _vm.dashboard.cities },
                    }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "col-md-6 col-sm-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v("Top 10 servicios este mes"),
                ]),
                _vm.loading
                  ? _c("el-skeleton", {
                      staticStyle: {
                        width: "100%",
                        padding: "10px",
                        height: "314px",
                      },
                      attrs: { rows: 6 },
                    })
                  : _c("ServicesBarChart", {
                      staticClass: "p-2",
                      attrs: { data: _vm.dashboard.services },
                    }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "col-md-3 col-sm-5" },
      [_c("Notifications", { attrs: { data: _vm.dashboard.notifications } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }