var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", class: { "nav-open": _vm.$sidebar.showSidebar } },
    [
      _c("notifications"),
      _c(
        "side-bar",
        {
          attrs: { backgroundColor: _vm.colorBackground, id: "sidebar" },
          scopedSlots: _vm._u(
            [
              _vm.menus.length > 0 && this.userLogged
                ? {
                    key: "links",
                    fn: function () {
                      return [
                        _vm.isMenuVisible("/cliente/envios")
                          ? _c("sidebar-item", {
                              attrs: {
                                hideClassName: _vm.hideClassName,
                                link: {
                                  name: "Mis Envíos",
                                  icon: "nc-icon nc-delivery-fast",
                                  path: "/cliente/envios",
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.isMenuVisible("/cliente/rma")
                          ? _c("sidebar-item", {
                              attrs: {
                                hideClassName: _vm.hideClassName,
                                link: {
                                  name: "RMA",
                                  icon: "nc-icon nc-refresh-02",
                                  path: "/cliente/rma",
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.isMenuVisible("/cliente/envios-agrupados")
                          ? _c("sidebar-item", {
                              attrs: {
                                hideClassName: _vm.hideClassName,
                                link: {
                                  name: "Envíos agrupados",
                                  icon: "nc-icon nc-layers-3",
                                  path: "/cliente/envios-agrupados",
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.isMenuVisible("/importaciones")
                          ? _c(
                              "sidebar-item",
                              {
                                attrs: {
                                  hideClassName: _vm.hideClassName,
                                  link: {
                                    name: "Importaciones",
                                    icon: "nc-icon nc-cloud-upload-94",
                                    path: "*",
                                  },
                                },
                              },
                              [
                                _vm.isMenuVisible("/cliente/importacion")
                                  ? _c("sidebar-item", {
                                      attrs: {
                                        hideClassName: _vm.hideClassName,
                                        link: {
                                          name: "Envíos",
                                          path: "/cliente/importacion",
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm.isMenuVisible("/cliente/ImportacionesRma")
                                  ? _c("sidebar-item", {
                                      attrs: {
                                        hideClassName: _vm.hideClassName,
                                        link: {
                                          name: "RMA",
                                          path: "/cliente/ImportacionesRma",
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isMenuVisible("/cliente/incidencias")
                          ? _c("sidebar-item", {
                              attrs: {
                                hideClassName: _vm.hideClassName,
                                link: {
                                  name: "Gestión de Incidencias",
                                  icon: "nc-icon nc-settings-tool-66",
                                  path: "/cliente/incidencias",
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.isMenuVisible("/cliente/facturas")
                          ? _c("sidebar-item", {
                              attrs: {
                                hideClassName: _vm.hideClassName,
                                link: {
                                  name: "Facturas",
                                  icon: "nc-icon nc-money-coins",
                                  path: "/cliente/facturas",
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.isMenuVisible("/logistica")
                          ? _c(
                              "sidebar-item",
                              {
                                attrs: {
                                  hideClassName: _vm.hideClassName,
                                  link: {
                                    name: "Logística",
                                    icon: "nc-icon nc-app",
                                    path: "*",
                                  },
                                },
                              },
                              [
                                _vm.isMenuVisible("/logistica/articulos")
                                  ? _c("sidebar-item", {
                                      attrs: {
                                        hideClassName: _vm.hideClassName,
                                        link: {
                                          name: "Artículos",
                                          path: "/logistica/articulos",
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm.isMenuVisible("/logistica/packs")
                                  ? _c("sidebar-item", {
                                      attrs: {
                                        hideClassName: _vm.hideClassName,
                                        link: {
                                          name: "Packs",
                                          path: "/logistica/packs",
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm.isMenuVisible("/logistica/ordenes-entrada")
                                  ? _c("sidebar-item", {
                                      attrs: {
                                        hideClassName: _vm.hideClassName,
                                        link: {
                                          name: "Ordenes entrada",
                                          path: "/logistica/ordenes-entrada",
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm.isMenuVisible("/logistica/ordenes-salida")
                                  ? _c("sidebar-item", {
                                      attrs: {
                                        hideClassName: _vm.hideClassName,
                                        link: {
                                          name: "Ordenes salida",
                                          path: "/logistica/ordenes-salida",
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isMenuVisible("/cliente/libretaContacto")
                          ? _c("sidebar-item", {
                              attrs: {
                                hideClassName: _vm.hideClassName,
                                link: {
                                  name: "Libreta de Contactos",
                                  icon: "nc-icon nc-badge",
                                  path: "/cliente/libretaContacto",
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.isMenuVisible("/cliente/usuarios")
                          ? _c("sidebar-item", {
                              attrs: {
                                hideClassName: _vm.hideClassName,
                                link: {
                                  name: "Usuarios",
                                  icon: "nc-icon nc-single-02",
                                  path: "/cliente/usuarios",
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [_c("mobile-menu")],
        1
      ),
      _c(
        "div",
        { staticClass: "main-panel" },
        [
          _c("top-navbar"),
          _c("dashboard-content", { on: { click: _vm.toggleSidebar } }),
          _c("content-footer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }